import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SideBar from './components/SideBar';
import Main from './pages/Main';
import Users from './pages/Users';
import Exercise from './pages/Exercise';
import Counsel from './pages/Counsel';
import UserDetail from './pages/UserDetail';
import UserReport from './pages/UserReport';
import UserCounsel from './pages/UserCounsel';
import UserPost from './pages/UserPost';
import UserComment from './pages/UserComment';
import UserCounselDetail from './pages/UserCounselDetail';
import Survey from './pages/Survey';
import Community from './pages/Community';
import SurveyDetail from './pages/SurveyDetail';
import { UserProvider } from './Contexts/UserContext';
import CommunityRemoved from './pages/CommunityRemoved';
import ExerciseDetail from './pages/ExerciseDetail';
import BackupExercise from './pages/BackupExercise';
import BackupExerciseDetail from './pages/BackupExerciseDetail';
import BackupSurvey from './pages/BackupSurvey';
import BackupSurveyDetail from './pages/BackupSurveyDetail';
import UserAnalyze from './pages/UserAnalyze';
import UserExerciseRate from './pages/UserExerciseRate';
import CheckOutOptions from './pages/CheckOutOptions';
import UserPremium from './pages/UserPremium';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const sessionAuth = sessionStorage.getItem('authenticated');
    if (sessionAuth) {
      setAuthenticated(true);
    }
  }, []);

  return (
    <UserProvider>
      <Router>
        <div className="flex h-screen">
          {authenticated && <SideBar />}
          <main className="flex-grow p-8 overflow-y-scroll h-screen bg-white">
            <Routes>
              <Route path="/" element={<Main setAuthenticated={setAuthenticated} />} />
              {authenticated && (
                <>
                  <Route path="/users" element={<Users />} />
                  <Route path="/userdetail" element={<UserDetail />} />
                  <Route path="/userreport" element={<UserReport />} />
                  <Route path="/userpremium" element={<UserPremium />} />
                  <Route path="/usercounsel" element={<UserCounsel />} />
                  <Route path="/usercounseldetail" element={<UserCounselDetail />} />
                  <Route path="/userexerciserate" element={<UserExerciseRate />} />
                  <Route path="/useranalyze" element={<UserAnalyze />} />
                  <Route path="/userpost" element={<UserPost />} />
                  <Route path="/usercomment" element={<UserComment />} />
                  <Route path="/counsel" element={<Counsel />} />
                  <Route path="/exercise" element={<Exercise />} />
                  <Route path="/exercisedetail" element={<ExerciseDetail />} />
                  <Route path="/survey" element={<Survey />} />
                  <Route path="/surveydetail" element={<SurveyDetail />} />
                  <Route path="/backup/exercise" element={<BackupExercise />} />
                  <Route path="/backup/exercisedetail" element={<BackupExerciseDetail />} />
                  <Route path="/backup/survey" element={<BackupSurvey />} />
                  <Route path="/backup/surveydetail" element={<BackupSurveyDetail />} />
                  <Route path="/community" element={<Community />} />
                  <Route path="/communityremoved" element={<CommunityRemoved />} />
                  <Route path="/checkoutoptions" element={<CheckOutOptions />} />
                </>
              )}
            </Routes>
          </main>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;